import type {
  EventEntry,
  EventSample,
  EventStudentPayload,
  IEventFormInput,
  RoomEntry,
} from '@epitech/ops-panoramix-events-types';
import { EventTimeline } from '@epitech/ops-panoramix-events-types/dist/events/validators/Timeline.validator';
import {
  CohortGroup,
  EventRef,
  ModuleRef,
  ProjectGroupRef,
  RoomRef,
  Stored,
  UserRef,
} from '@epitech/ops-panoramix-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { ResourceListForEventArgs } from '@/components/events';
import { environment } from '@/config/environment';
import { RawDates, RawDatesWithSlots, RawRegisteredSample } from '@/lib/types/events';

import { customFetchQuery } from './baseQueryWithReauth';
import { DEFAULT_PAGINATION, IPaginatedRequest, providesList } from './helpers';

export interface IUnregisteredByEdit
  extends Omit<EventEntry, 'cohortGroups' | 'roomsRef' | 'outlookRef' | 'moduleRef' | 'slots'> {
  registeredGroups: ProjectGroupRef[];
  registeredUsers: UserRef[];
  registeredStaffs: UserRef[];
}

export interface ISanitizedPartialEvent extends Stored<EventTimeline> {
  allDay: boolean;
  color?: string;
}

export interface ISearchEventsRequest extends IPaginatedRequest {
  start?: string;
  end?: string;
  search?: string;
}

export interface ISearchRoomsRequest extends IPaginatedRequest {
  search?: string;
}

export interface ISearchRoomEventsRequest extends IPaginatedRequest {
  start?: string;
  end?: string;
  search?: string;
}

type RegisterToEventArgs = {
  eventId: string;
  slotIdx?: number;
  toRegister: UserRef | ProjectGroupRef;
};

type ForceRegisterUsersToEventArgs = {
  eventId: string;
  usersToRegister?: UserRef[];
  cohortGroups?: CohortGroup[];
  moduleRef?: ModuleRef;
  slotIdx?: number;
};

type MassUnregisterRoomFromSlotArgs = {
  eventId: string;
  slotIdx?: number[];
};

type UnregisterRoomFromSlotArgs = {
  eventId: string;
  slotIdx?: number;
};

type ForceRegisterStaffsToEventArgs = {
  eventId: string;
  usersToRegister: UserRef[];
  slotIdx?: number;
};

type MassForceRegisterStaffsToEventArgs = {
  eventId: string;
  usersToRegister: UserRef[];
  slotIdx?: number[];
};

type MassForceRegisterRoomsToEventArgs = {
  eventId: string;
  roomsToRegister: RoomRef[];
  slotIdx?: number[];
};

type ForceRegisterRoomsToEventArgs = {
  eventId: string;
  roomsToRegister: RoomRef[];
  slotIdx?: number;
};

type ForceRegisterGroupsToEventArgs = {
  eventId: string;
  groupsToRegister: ProjectGroupRef[];
  slotIdx?: number;
};

type UnregisterFromEventArgs = {
  eventId: string;
  resource: ResourceListForEventArgs;
  resourceId: string;
  staff?: boolean;
  slotIdx?: number;
};

type ForceUnregisterUsersFromEventArgs = {
  eventId: string;
  cohortGroups?: CohortGroup[];
  moduleRef?: ModuleRef;
};

type UnregisterStaffFromEventArgs = {
  eventId: string;
  userId: string;
  slotIdx?: number;
};

type EventMutationArgs = {
  ignoreWarnings?: boolean;
} & IEventFormInput;

type DeleteEventMutationArgs = {
  ignoreWarnings?: boolean;
  eventId: string;
};

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: customFetchQuery(environment.services.EVENTS_URI),
  tagTypes: ['Event', 'EventRef', 'EventSample', 'RoomRef', 'Room'],
  endpoints: builder => ({
    getEvents: builder.query<RawDates<EventSample>[], ISearchEventsRequest>({
      query: ({ start, end, search, limit, offset }) => {
        /** We do not want to limit when performing a search by dates */
        return queryString.stringifyUrl({
          url: '/events',
          query: {
            start,
            end,
            search,
            limit,
            offset,
          },
        });
      },
      providesTags: events => providesList(events, 'EventSample'),
    }),
    getEventsWithRegisteredStatus: builder.query<RawRegisteredSample[], ISearchEventsRequest>({
      query: ({ start, end, search, limit, offset }) => {
        /** We do not want to limit when performing a search by dates */
        return queryString.stringifyUrl({
          url: '/events',
          query: {
            start,
            end,
            search,
            registrationStatus: true,
            limit,
            offset,
          },
        });
      },
      providesTags: events => providesList(events, 'EventSample'),
    }),
    /** Different method as this return `EventsRef` and not full events */
    searchEvents: builder.query<RawDates<EventRef>[], ISearchEventsRequest>({
      query: ({
        start,
        end,
        search,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl({
          url: '/events',
          query: {
            start,
            end,
            search,
            limit,
            offset,
            refOnly: true,
          },
        }),
      providesTags: events => providesList(events, 'EventRef'),
    }),
    getEvent: builder.query<RawDatesWithSlots<Stored<EventEntry | EventStudentPayload>>, string>({
      query: eventId => `/events/${eventId}`,
      providesTags: event => [{ type: 'Event', id: event?._id }],
      transformResponse: (
        response: RawDatesWithSlots<Stored<EventEntry | EventStudentPayload>>,
      ) => {
        response.registeredUsers?.sort((a, b) => a.login.localeCompare(b.login));
        response.registeredStaffs?.sort((a, b) => a.login.localeCompare(b.login));
        response.slots?.forEach(slot => {
          slot.registeredStaffs.sort((a, b) => a.login.localeCompare(b.login));
          slot.registeredGroup?.usersRef.sort((a, b) => a.login.localeCompare(b.login));
        });
        return response;
      },
    }),
    createEvent: builder.mutation<RawDatesWithSlots<Stored<EventEntry>>, EventMutationArgs>({
      query: body => ({
        url: queryString.stringifyUrl({
          url: '/events',
          query: { ignoreWarnings: body.ignoreWarnings || false },
        }),
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: 'Event', id: 'LIST' },
        { type: 'EventSample', id: 'LIST' },
      ],
    }),
    updateEvent: builder.mutation<RawDatesWithSlots<Stored<EventEntry>>, EventMutationArgs>({
      query: patch => ({
        url: queryString.stringifyUrl({
          url: `/events/${patch._id}`,
          query: { ignoreWarnings: patch.ignoreWarnings || false },
        }),
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: event =>
        event
          ? [
              { type: 'Event', id: event?._id },
              { type: 'EventSample', id: event?._id },
            ]
          : [],
    }),
    deleteEvent: builder.mutation<void, DeleteEventMutationArgs>({
      query: ({ eventId, ignoreWarnings = false }) => ({
        url: queryString.stringifyUrl({
          url: `/events/${eventId}`,
          query: { ignoreWarnings },
        }),
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Event', id: 'LIST' },
        { type: 'EventSample', id: 'LIST' },
      ],
    }),
    registerToEvent: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry | EventStudentPayload>>,
      RegisterToEventArgs
    >({
      query: ({ eventId, slotIdx, toRegister }) => {
        const baseUrl = `/events/${eventId}/`;
        const resource = 'login' in toRegister ? 'users/' : 'projectGroups/';
        const url = queryString.stringifyUrl({
          url: baseUrl + resource + toRegister._id,
          query: { slotIdx },
        });
        return {
          url,
          body: toRegister,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    forceRegisterUsersToEvent: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry>>,
      ForceRegisterUsersToEventArgs
    >({
      query: ({ eventId, usersToRegister = [], slotIdx, cohortGroups = [], moduleRef }) => {
        const reqBody =
          usersToRegister.length > 0 || slotIdx ? usersToRegister : { cohortGroups, moduleRef };
        return {
          url: queryString.stringifyUrl({
            url: `/events/${eventId}/force_register/users`,
            query: { slotIdx },
          }),
          body: reqBody,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    forceRegisterGroupsToEvent: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry>>,
      ForceRegisterGroupsToEventArgs
    >({
      query: ({ eventId, groupsToRegister, slotIdx }) => {
        const baseUrl = `/events/${eventId}/force_register/groups`;
        let slot = '';
        if (slotIdx !== undefined) slot = `?slotIdx=${slotIdx}`;

        return {
          url: baseUrl + slot,
          body: groupsToRegister,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    forceRegisterStaffsToEvent: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry>>,
      ForceRegisterStaffsToEventArgs
    >({
      query: ({ eventId, usersToRegister, slotIdx }) => {
        const baseUrl = `/events/${eventId}/force_register/staffs`;
        let slot = '';
        if (slotIdx !== undefined) slot = `?slotIdx=${slotIdx}`;

        return {
          url: baseUrl + slot,
          body: usersToRegister,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    forceRegisterRoomsToEvent: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry>>,
      ForceRegisterRoomsToEventArgs
    >({
      query: ({ eventId, roomsToRegister, slotIdx }) => {
        const baseUrl = `/events/${eventId}/register/room`;
        const slot = `?slotIdx=${slotIdx}`;

        return {
          url: baseUrl + slot,
          body: roomsToRegister,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    massForceRegisterRoomsToEvent: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry>>,
      MassForceRegisterRoomsToEventArgs
    >({
      query: ({ eventId, roomsToRegister, slotIdx }) => {
        const baseUrl = `/events/${eventId}/register/mass/room`;
        let slot = '?';
        if (slotIdx && slotIdx?.length > 0) {
          slotIdx?.forEach((el, index) => {
            slot += `slotIdx[]=${el}`;
            if (index <= slotIdx.length - 1) {
              slot += '&';
            }
          });
        }

        return {
          url: baseUrl + slot,
          body: roomsToRegister,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    massForceRegisterStaffsToEvent: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry>>,
      MassForceRegisterStaffsToEventArgs
    >({
      query: ({ eventId, usersToRegister, slotIdx }) => {
        const baseUrl = `/events/${eventId}/force_register/mass/staffs`;
        let slot = '?';
        // TODO: use query-string `stringifyUrl`
        if (slotIdx && slotIdx?.length > 0) {
          slotIdx?.forEach((el, index) => {
            slot += `slotIdx[]=${el}`;
            if (index <= slotIdx.length - 1) {
              slot += '&';
            }
          });
        }

        return {
          url: baseUrl + slot,
          body: usersToRegister,
          method: 'POST',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    unregisterFromEvent: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry | EventStudentPayload>>,
      UnregisterFromEventArgs
    >({
      query: ({ eventId, resource, resourceId, slotIdx }) => {
        let slot = '';
        if (slotIdx !== undefined) slot = `?slotIdx=${slotIdx}`;

        return {
          url: `/events/${eventId}/${resource}/${resourceId}${slot}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    unregisterStaffFromEvent: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry>>,
      UnregisterStaffFromEventArgs
    >({
      query: ({ eventId, userId, slotIdx }) => {
        const baseUrl = `/events/${eventId}/staffs/${userId}`;
        let slot = '';
        if (slotIdx !== undefined) slot = `?slotIdx=${slotIdx}`;

        return {
          url: baseUrl + slot,
          method: 'DELETE',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    forceUnregisterUsersFromEvent: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry>>,
      ForceUnregisterUsersFromEventArgs
    >({
      query: ({ eventId, cohortGroups, moduleRef }) => {
        return {
          url: `/events/${eventId}/users`,
          method: 'DELETE',
          body: {
            cohortGroups,
            moduleRef,
          },
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    searchRooms: builder.query<RoomRef[], ISearchRoomsRequest>({
      query: ({
        search,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl({
          url: '/rooms',
          query: {
            search,
            limit,
            offset,
            refOnly: true,
          },
        }),
      providesTags: rooms => providesList(rooms, 'RoomRef'),
    }),
    getRooms: builder.query<RoomEntry[], ISearchRoomsRequest>({
      query: ({
        search,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl({
          url: '/rooms',
          query: {
            search,
            limit,
            offset,
            refOnly: false,
          },
        }),
      providesTags: rooms => providesList(rooms, 'Room'),
    }),
    searchRoomEvents: builder.query<RawDates<Stored<EventTimeline>>[], ISearchRoomEventsRequest>({
      query: ({
        start,
        end,
        search,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl({
          url: '/rooms/events',
          query: {
            start,
            end,
            search,
            limit,
            offset,
          },
        }),
      providesTags: events => providesList(events, 'Event'),
    }),
    unregisterRoomToSlot: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry>>,
      UnregisterRoomFromSlotArgs
    >({
      query: ({ eventId, slotIdx }) => {
        const baseUrl = `/events/${eventId}/register/room`;
        let slot = '';
        if (slotIdx !== undefined) slot = `?slotIdx=${slotIdx}`;

        return {
          url: baseUrl + slot,
          method: 'DELETE',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
    massUnregisterRoomToSlot: builder.mutation<
      RawDatesWithSlots<Stored<EventEntry>>,
      MassUnregisterRoomFromSlotArgs
    >({
      query: ({ eventId, slotIdx }) => {
        const baseUrl = `/events/${eventId}/register/mass/room`;
        let slot = '?';
        if (slotIdx && slotIdx?.length > 0) {
          slotIdx?.forEach((el, index) => {
            slot += `slotIdx[]=${el}`;
            if (index <= slotIdx.length - 1) {
              slot += '&';
            }
          });
        }

        return {
          url: baseUrl + slot,
          method: 'DELETE',
        };
      },
      invalidatesTags: event => [
        { type: 'Event', id: event?._id },
        { type: 'EventSample', id: event?._id },
      ],
    }),
  }),
});

export const {
  useGetEventQuery,
  useLazyGetEventQuery,
  useGetEventsWithRegisteredStatusQuery,
  useLazyGetEventsWithRegisteredStatusQuery,
  useSearchEventsQuery,
  useGetRoomsQuery,
  useSearchRoomEventsQuery,
  useLazySearchEventsQuery,
  useSearchRoomsQuery,
  useLazySearchRoomsQuery,
  useGetEventsQuery,
  useLazyGetEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useRegisterToEventMutation,
  useUnregisterRoomToSlotMutation,
  useMassUnregisterRoomToSlotMutation,
  useForceRegisterUsersToEventMutation,
  useForceRegisterGroupsToEventMutation,
  useForceRegisterStaffsToEventMutation,
  useForceRegisterRoomsToEventMutation,
  useMassForceRegisterStaffsToEventMutation,
  useMassForceRegisterRoomsToEventMutation,
  useUnregisterFromEventMutation,
  useUnregisterStaffFromEventMutation,
  useForceUnregisterUsersFromEventMutation,
} = eventsApi;
